.contact {

    .textarea-container textarea {
    max-width: 100%;
    max-height: 80px;
    padding: 10px 10px 0 0;
    resize: none;
    border: none !important;
    border-bottom: 1px solid #e3e3e3 !important;
    border-radius: 0;
    line-height: 2;
    }
    .input-group.input-lg .input-group-text {
        padding: 14px 14px 15px 18px;
    }
    .form-col {
        padding: 0 6rem;
        @media screen and (max-width: 768px){
            padding: 0 3rem;
            
        }
    }
    

}