.modal-dialog {
    max-width: 100vw;
    .modal-content{ 
        height: 66vh;
    }
    .modal-body {
        div {
            height: 100%;
        }
    }
}