.navbar-nav .nav-item {
    .now-ui-icons.ui-1_email-85, .now-ui-icons.ui-1_settings-gear-63, .shopping_credit-card {
        top: 0 !important;

    }
    .nav-link {
        transition: .4s all ease;
        &:hover {
            color: #4890CD;
        }
    }
}
.navbar-brand {
    svg {
        height: 40px;
    }
    .st1, .st0, .st2 {
        color: white;
        transition: all .4s ease;
    }
    &:hover {
        svg {
            .st1 {
                fill: #4890CD;
                
            }
            .st0 {
                fill: #FED20D;
            }
        }
        
    }
}