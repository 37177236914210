.hiw-icon {
    border-radius: 100%;
    width: 80px;
    height: 80px;
    margin: 0 auto;
    background-color: #4890CD;

    svg {
        height: 80px;
        padding: 1rem;

    }
}